import { STATUS_OK, STATUS_NOK } from '../helpers/constants'

const axios = require('axios')
const { CustomError } = require('../helpers/error')

const checkExistVariable = (x) => {
    if (typeof x === 'undefined') throw new CustomError('Variable indefinida')
    else if (!x.length) throw new CustomError('Variable vacía')
    else return x
}

const stringToPk = (coursePk) => {
    const regex_match = /_(.*)_1/
    let coursePk_regex = coursePk.match(regex_match)
    let bb_coursePK1_DB = coursePk_regex == null ? null : coursePk_regex[1]

    return  isNaN(bb_coursePK1_DB) ? null : bb_coursePK1_DB * 1
}

const convertUpperFromArray = (arr) => {
    const result = arr.map(element => {
        return element.toUpperCase();
    });

    return result
}

const bbGenerateError = (error, externalMessage) => {
    let type = "Axios"
    let statusCode = null
    let message = null
  
    if (axios.isAxiosError(error)) {
      statusCode = error.response.status
      message = error.response.data.message
    } else {
      message = error.message
      type = error.name
      statusCode = ('status' in error) ? error.status : 500
    }

    return {
      type: type,
      statusCode: statusCode,
      message: externalMessage ? externalMessage : message,
      status: STATUS_NOK
    }
}

const bbGetObject = async (accessToken, url) => {
    try {
        let response = await axios.get(url, {
            responseType: 'json',
            headers: { "Authorization": accessToken }
        })

        return {
            "status": STATUS_OK,
            "data": response.data
        }
    } catch (e) {
        return bbGenerateError(e)
    }
}

const bbGetMultipleList = async (accessToken, url) => {
    try {
        let arr = []
        let nextPage = true
        let nextpageURL = null
        
        while (nextPage) {
            if (nextpageURL != null) url = process.env.BB_URL + nextpageURL

            let response = await axios.get(url, {
                responseType: 'json',
                headers: { "Authorization": accessToken }
            })
            
            if (response.status === 200) {
                let results = response.data.results
                arr = arr.concat(results)
                
                if ("paging" in response.data) {                 
                    nextpageURL = response.data.paging.nextPage
                    nextPage = true
                }
                else nextPage = false
            } 
            else nextPage = false
        }

        return {
            "status": STATUS_OK,
            "data": {
                "results": arr,
                "size": arr.length
            }
        }
    } catch (e) {
        return bbGenerateError(e)
    }
}

const bbDataManipulation = async (accessToken, url, method, data = {}) => {
    try {
        let response = await axios({
            method: method,
            url: url,
            responseType: 'json',
            headers: { "Authorization": accessToken },
            data: data
        })
      
        return {
            "status": STATUS_OK,
            "data": response.data
        }

    } catch (e) {
        return bbGenerateError(e)
    }
}

const lambdaGenerateSuccess = (message) => {

    return {
      statusCode: 200,
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(
        {
          status: STATUS_OK,
          message: message
        }
      )
    } 
}

const lambdaGenerateError = (e) => {
    let message = e.message
    let status = e.status ? e.status : 500
    
    return {
      statusCode: status,
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(
        {
          message: message,
        }
      )
    } 
}

const responseError = (response, jsonError) => {    
    return response.status(jsonError.statusCode)
        .send(JSON.stringify(
            {
                message: jsonError.message,
            }
        )) 
}

const getUserInfo = (user, coursePk, courseId, contentPk, contentStartDate, event_type, data = null) => {
    const json = {
        userPk: user.userPkDB,
        userId: user.userId,
        coursePk: coursePk,
        courseId: courseId,
        contentPk: contentPk,
        customStartDate: contentStartDate,
        browser_name: user.browser_name,
        browser_version: user.browser_version,
        os_name: user.os_name,
        os_version: user.os_version,
        device_type: user.device_type,
        device_brand: user.device_brand,
        publicIP: user.publicIP,
        event_type: event_type,
        data: data
    }

    return json
}

export { bbGenerateError, bbGetMultipleList, bbGetObject
    , bbDataManipulation, lambdaGenerateError, checkExistVariable
    , convertUpperFromArray, lambdaGenerateSuccess, responseError
    , getUserInfo, stringToPk }

/*
module.exports = { bbGenerateError, bbGetMultipleList, bbGetObject
    , bbDataManipulation, lambdaGenerateError, checkExistVariable
    , convertUpperFromArray, lambdaGenerateSuccess, responseError
    , getUserInfo }
*/