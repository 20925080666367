import React, { useEffect, useContext } from "react";
import { Navbar } from "react-bootstrap"
import { Container} from "react-bootstrap";
import logo from '../../images/default_profile_avatar.svg';
import { userContext } from '../../app/provider.js';

import './style.css'

const Header = (props) => {
    const [user, setUser] = useContext(userContext);

    useEffect(() => {
        
    }, [])

    return (
        <Navbar variant="dark" sticky="top" className='navHeader'>
            <Container fluid className='d-flex justify-content-end'>
                <Navbar.Brand href="#home">
                <a className="navbar-brand" href="#">                                                
                    <img className="mx-1 rounded-circle" src={ logo } width="30" height="30" alt="logo" />
                    <span className="text-white fw-bolder fs-6">{ user.fullname }</span>
                </a>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default Header