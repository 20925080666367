export const STATUS_CODE_OK = 200
export const STATUS_CODE_NOK = 500
export const STATUS_OK = "OK"
export const STATUS_NOK = "NOK"
export const AVAILABLE_OK = "Yes"
export const AVAILABLE_NOK = "No"
export const ROL_STUDENT = "Student"
export const RULE_TITLE_DEFAULT = "API - Rule"
export const CRITERIA_TYPE_DATE_RANGE = "DateRange"
export const CRITERIA_TYPE_GRADE_RANGE = "GradeRange"
export const CRITERIA_TYPE_MEMBERSHIPS = "Memberships"
export const ES_GROUP_SET = "ES HABILITADOS"
export const ES_GROUP = "GRUPO - ES HABILITADOS"
export const MSJ_COURSE_NOT_FOUND = "No existen cursos disponibles."
export const MSJ_DEFAULT_MISSING_ACTIVITIES = "Seleccione una opción para visualizar sus actividades pendientes."
export const EVENT_TYPE_MISSING_ACTIVITY_GROUP = 'MISSING-ACTIVITY-GROUP'
export const EVENT_TYPE_COURSE_PROGRESS = 'COURSE-PROGRESS-ITEM'
export const EVENT_TYPE_LOAD_COURSES = 'LOAD-COURSES'
export const EVENT_TYPE_LOGIN_APP = 'LOGIN-APP'
export const EVENT_TYPE_MISSING_ACTIVITY_ITEM = 'MISSING-ACTIVITY-ITEM'

/*
module.exports = {
  STATUS_CODE_OK: 200,
  STATUS_CODE_NOK: 500,
  STATUS_OK: "OK",
  STATUS_NOK: "NOK",
  AVAILABLE_OK: "Yes",
  AVAILABLE_NOK: "No",
  ROL_STUDENT: "Student",
  RULE_TITLE_DEFAULT: "API - Rule",
  CRITERIA_TYPE_DATE_RANGE: "DateRange",
  CRITERIA_TYPE_GRADE_RANGE: "GradeRange",
  CRITERIA_TYPE_MEMBERSHIPS: "Memberships",
  ES_GROUP_SET: "ES HABILITADOS",
  ES_GROUP: "GRUPO - ES HABILITADOS",
  MSJ_COURSE_NOT_FOUND: "No existen cursos disponibles.",
  MSJ_DEFAULT_MISSING_ACTIVITIES: "Seleccione una opción para visualizar sus actividades pendientes.",
  EVENT_TYPE_MISSING_ACTIVITY_GROUP: 'MISSING-ACTIVITY-GROUP',
  EVENT_TYPE_COURSE_PROGRESS: 'COURSE-PROGRESS-ITEM',
  EVENT_TYPE_LOAD_COURSES: 'LOAD-COURSES',
  EVENT_TYPE_LOGIN_APP: 'LOGIN-APP',
  EVENT_TYPE_MISSING_ACTIVITY_ITEM: 'MISSING-ACTIVITY-ITEM'
};
*/

