import React, { useState, useEffect, useContext } from "react";
import CheckIcon from '@material-ui/icons/Check';
import './style.css'

const NotificationContainer = ({}) => {

    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log("NotificationContainer", "Loading")
    }, [])

    return (
        <footer className="panel-footer notification is-active">
            <div className="notification-container success">
                {/*
                    <span class="progress-indicator"><CheckIcon/></span>
                    <span class="progress-status fs-7">¡Elementos de contenido cargados!</span>
                */
                }
                <span class="progress-indicator loading"/>
                <span class="progress-status fs-7">Cargando más elementos de contenido...</span>
            </div>
        </footer>
    );
};

export default NotificationContainer