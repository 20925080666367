import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { modalContext } from '../../app/provider.js';
import api from '../../api/bb.js'
import * as Constants from '../../constants'
import ModalCustom from "../../components/ModalCustom";
import LayerGradebook from "../../components/LayerGradebook";
import Loader from "../../components/Loader";
import NotificationContainer from "../../components/NotificationContainer";

import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DataTable from 'react-data-table-component';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import Moment from 'react-moment';

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import './style.css'

const LayerEnrollment = ({ courseId, modalId }) => {
    const ROWS_PER_PAGE = process.env.REACT_APP_ROWS_PER_PAGE
    const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT

    const [modal, setModal] = useContext(modalContext);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoadingHasMore, setIsLoadingHasMore] = useState(false);
    const [data, setData] = useState([]);
    const observerTarget = useRef(null);
    const [hasMore, setHasMore] = useState(true)
    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(ROWS_PER_PAGE)

    const [courseSelected, setCourseSelected] = useState(undefined)
    const [userSelected, setUserSelected] = useState(undefined)

    const fetchData = useCallback(async (page) => {
        console.log("fetchData", page, pageLength, courseId)
        let response = await api.fetchMemberships(page, pageLength, courseId)        
        if(page == 1) setIsLoadingPage(false)
        setIsLoadingHasMore(false)
        setData(prevData => [...prevData, ...response.results])
        setPage(prevPage => prevPage + 1)
        if (!response.paging.nextPage) setHasMore(false)
    }, [])

    const handleOnClickOption = (e, data) => {        
        let modalCopy = {...modal}
        modalCopy.array[Constants.MODAL_ENROLL_GRADEBOOK].open = true
        modalCopy.array[Constants.MODAL_ENROLL_GRADEBOOK].previous = modalId
        modalCopy.array[Constants.MODAL_ENROLL_GRADEBOOK].header.subtitle = data.user.givenName + " " + data.user.familyName

        setModal(modalCopy)
        setCourseSelected(data.courseId)
        setUserSelected(data.userId)
    }; 
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    let page = observerTarget.current.attributes['data-page'].value
                    console.log("isIntersecting", page)
                    if (page > 1) {
                        console.log("Cargando scroll...", page)
                        setIsLoadingHasMore(true)
                    }

                    fetchData(page)
                }
            },
            { threshold: 1 }
        );
      
        if (observerTarget.current) {
          observer.observe(observerTarget.current);
        }
      
        return () => {
          if (observerTarget.current) {
            observer.unobserve(observerTarget.current);
          }
        };
    }, [observerTarget]);

    const columns = [
        {
            cell: row => (
                <>
                {
                    (row.user.isAvailable == false || row.user.rowStatus == Constants.API_MEMBERSHIP_DISABLED_IND) ? <PersonAddDisabledIcon className="text-danger"/> :
                    (row.isAvailable == false || row.rowStatus == Constants.API_MEMBERSHIP_DISABLED_IND ? <BlockIcon className="text-danger"/> : 
                    <CheckCircleOutlineIcon className="text-success"/>)
                }
                </>
            ),
            width: '3.5rem',
            style: {
                borderBottom: '1px solid #FFFFFF',
                marginBottom: '-1px',
            },
        },          
        {
            name: 'Código',
            selector: row => row.user.userName,
        },
        {
            name: 'Nombres',
            selector: row => row.user.givenName,
        },
        {
            name: 'Apellidos',
            selector: row => row.user.familyName,
        },
        {
            name: 'Fec. matrícula',
            selector: row => (
                <>
                { row.enrollmentDate ? <Moment date={row.enrollmentDate} format={DATETIME_FORMAT} /> : "-" }
                </>
            )
        },
        {
            name: 'Fec. ult. acceso',
            selector: row => (
                <>
                { row.lastAccessDate ? <Moment date={row.lastAccessDate} format={DATETIME_FORMAT} /> : "-" }
                </>
            )
        },
        {
            name: 'Rol',
            selector: row => (
                <>
                {
                    <span class={ row.role == "S" ? "" : "user-role" }> 
                    { row.courseRole.courseName.bundle ? row.courseRole.courseName.languageKey : row.courseRole.courseName.rawValue }
                    </span>                    
                }
                </>
            )
        },
        {
            cell: row => <>
                <Menu menuButton={<MenuButton><IconButton><MoreHorizIcon/></IconButton></MenuButton>} 
                    transition
                    onItemClick={(e) => handleOnClickOption(e, row)}
                >
                    <MenuItem value="gradebook"><MenuBookIcon fontSize='small' className='me-1'/>Libro de calificaciones</MenuItem>
                </Menu>           
            </>,
            allowOverflow: true,
            button: true
        }             
    ];

    return (        
        <>
        { isLoadingPage && <Loader/> }
        <div>
            <ModalCustom 
                identifier={Constants.MODAL_ENROLL_GRADEBOOK}
                previus={Constants.MODAL_COURSE_ENROLL}
                size={95}
                content={ 
                    <LayerGradebook
                        courseId={ courseSelected ? courseSelected : undefined }
                        userId={ userSelected ? userSelected : undefined }
                    />
                }
            />
            <div class="tabular-list-container roster-list-view">
                <DataTable columns={columns} data={data} noDataComponent="" />
            </div>
        
            { hasMore &&
                <div ref={observerTarget} data-page={page}></div>
            }

            { isLoadingHasMore && <NotificationContainer/> }
        </div>
        </>
    );
};

export default LayerEnrollment