import {publicIp, publicIpv4, publicIpv6} from 'public-ip';

const helpers = {
    getClientIp: async function () {
        const clientIp = await publicIpv4()
        return clientIp
    },
    stringToRoles: function(roles, userRol) {
        let arrRoles = []
        let userPermision = false

        if (roles) {
            let arrSplit = roles.split(",")
            
            for (let idx in arrSplit) {
                let value = arrSplit[idx].trim();
                if (value.length > 0) {
                    if (value == userRol) userPermision = true
                    arrRoles.push(value)
                }
            }
        }
        
        return {
            userPermision: userPermision,
            arr: arrRoles
        }
    },
}

export default helpers;