import axios from 'axios';
import { STATUS_OK, STATUS_NOK } from '../helpers/constants'

const { leftJoin } = require('array-join')
const { bbGenerateError } = require('../helpers/utils')
const Helpers = require('../helpers')
const PUBLIC_URL = process.env.REACT_APP_BACKEND_URL

const fetchGradebook = (page, pageLength, courseId, userId) =>
    axios.all([
        axios.get(`${PUBLIC_URL}/courses/${courseId}/gradebook/columns?page=${page}&pageLength=${pageLength}`),
        axios.get(`${PUBLIC_URL}/courses/${courseId}/gradebook/users/${userId}?page=${page}&pageLength=${pageLength}`),
        axios.get(`${PUBLIC_URL}/courses/${courseId}/gradebook/categories`)
    ]).then(axios.spread((columns, gradebook, categories) => {

        // left_accessor = function (obj) { return obj['id'] }
        // right_accessor = function (obj) { return obj['columnId'] }
        // console.log("Longitud", b2_contents.length, bb_contents.length)
        // console.log("Longitud", bb_contents.results)
        // let contents = _.hashLeftOuterJoin(b2_contents, left_accessor, bb_contents, right_accessor)

        let arrCategories = categories.data.results.map(el => ({category_id: el.id, category_title:el.title}));

        console.log("results ohh 1", columns.data, gradebook.data, arrCategories)
        let prepData = leftJoin(
            columns.data.results,
            gradebook.data.results,
            (left) => left.id,
            (right) => right.columnId,
            (left, right) => ({ ...left, ...right })
        )

        let results = leftJoin(
            prepData,
            arrCategories,
            (left) => left.gradebookCategoryId,
            (right) => right.category_id,
            (left, right) => ({ ...left, ...right })
        )        
        console.log("results ohh 2", results)
        
        return {
            results: results
        }
        /*
        return {
            results: {
                columns: columns.data,
                gradebook: gradebook.data
            }
        }
        */
    })).catch(err => {
        console.log("results ohh err", err)
        return err.response
    })

const fetchCourses = (page, pageLength, filters) =>
    axios.get(Helpers.buildUrl(`${PUBLIC_URL}/courses?page=${page}&pageLength=${pageLength}`, filters))
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })

const fetchUsers = (page, pageLength, filters) =>
    axios.get(Helpers.buildUrl(`${PUBLIC_URL}/users?page=${page}&pageLength=${pageLength}`, filters))
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })

const fetchMemberships = (page, pageLength, courseId) =>
    axios.get(`${PUBLIC_URL}/internal/courses/${courseId}/memberships?page=${page}&pageLength=${pageLength}`)
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })

const fetchColumns = (page, pageLength, courseId) =>
    axios.get(`${PUBLIC_URL}/internal/courses/${courseId}/gradebook/columns?page=${page}&pageLength=${pageLength}`)
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })
    
const fetchGradesPerUser = (page, pageLength, courseId, userId) =>
    axios.get(`${PUBLIC_URL}/courses/${courseId}/gradebook/users/${userId}?page=${page}&pageLength=${pageLength}`)
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })

const fetchAttemptsPerUserColumn = (page, pageLength, courseId, userId, columnId) =>
    axios.get(`${PUBLIC_URL}/courses/${courseId}/gradebook/users/${userId}/columns/${columnId}/attempts?page=${page}&pageLength=${pageLength}`)
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })
    
const fetchMembershipsPerUser = (page, pageLength, userId) =>
    axios.get(`${PUBLIC_URL}/users/${userId}/memberships?page=${page}&pageLength=${pageLength}`)
    .then(response => {
        return response.data
    }).catch(err => {
        return err.response
    })    

const api = {
    fetchCourses,
    fetchMemberships,
    fetchColumns,
    fetchGradebook,
    fetchUsers,
    fetchGradesPerUser,
    fetchAttemptsPerUserColumn,
    fetchMembershipsPerUser
}

export default api