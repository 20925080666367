import React, { useState, useEffect, useContext } from 'react'
// import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { modalContext } from '../../app/provider.js';
import * as Constants from '../../constants'

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './style.css'

const ModalCustom = ({ identifier, previus, size, content }) => {
    const [modal, setModal] = useContext(modalContext);
    const [sizeWidth, setSizeWidth] = useState("100vw");
    const [sizeMargin, setSizeMargin] = useState("0vw");

    // const defaultPrevius = "UNDEFINED";

    useEffect(() => {
        console.log("Welcome to Modal", identifier, modal.modal_open)
        setSizeWidth(`${size}vw`)
        setSizeMargin(`${100-size}vw`)
    }, [])

    useEffect(() => {
        if (modal.array[identifier].open) {
            let modalCopy = {...modal}
            modalCopy.modal_open = modalCopy.modal_open + 1
            modalCopy.modal_active = identifier
            modalCopy.modal_previous = modalCopy.array[identifier].previous
            setModal(modalCopy)
        }        
    }, [modal.array[identifier].open])

    const handleOnHide = (e) => {
        let attr_modal_previous = e.target.attributes['data-modal-previous'] ? e.target.attributes['data-modal-previous'].value : undefined
        let modalCopy = {...modal}
        modalCopy.modal_open = modalCopy.modal_open - 1
        modalCopy.array[identifier].open = false
        modalCopy.modal_active = attr_modal_previous
        modalCopy.modal_previous = attr_modal_previous ? modalCopy.array[attr_modal_previous].previous : undefined
        setModal(modalCopy)
    }

    const closeBtn = (
        <button 
            className={ modal.modal_active == identifier ? "btn-close" : "btn-close d-none" } 
            type="button" onClick={(e) => handleOnHide(e)}
            data-name={ identifier }
            data-modal-previous={ previus }
        />
        
    );    

    return (
        <Modal isOpen={modal.array[identifier].open} fullscreen 
            // style={{width: '95vw', marginLeft: '5vw'}}
            style={{width: sizeWidth, marginLeft: sizeMargin}}
            className='custom-modal'
            // labelledBy='hoj'
        >
            <ModalHeader close={closeBtn}>
                <div className='modal-title'>
                    <p className="mb-0 fs-6">{ modal.array[identifier].header.subtitle }</p>
                    <strong><p className="mb-0 fs-3">{ modal.array[identifier].header.title }</p></strong>
                </div>            
            </ModalHeader>
            <ModalBody>
                { content }
            </ModalBody>
        </Modal>
    );
};

export default ModalCustom