import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Chart } from "react-chartjs-2";
import { Doughnut, Pie } from "react-chartjs-2";
import "chartjs-plugin-doughnutlabel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CourseGlobalProgress from "../../components/CourseGlobalProgress/index.js"
import SearcherBox from "../../components/SearcherBox/index.js"
import SearcherPageDropdown from "../../components/SearcherPageDropdown/index.js"

import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DtCustomPagination from "../../components/DtCustomPagination/index.js"

import './style.scss'
import { userContext, courseContext, modalContext } from '../../app/provider.js';
import api from '../../api/bb.js'
import DataTable from 'react-data-table-component';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Icon from '@material-ui/icons/Apps';
import PeopleIcon from '@material-ui/icons/People';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import ModalCustom from "../../components/ModalCustom/index.js";
import LayerEnrollmentPerUser from "../../components/LayerEnrollmentPerUser";
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import Moment from 'react-moment';

import * as Constants from '../../constants/index.js'

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Loader from "react-js-loader";

const User = () => {

    const [modal, setModal] = useContext(modalContext);
    const [pending, setPending] = useState(true);

    const [data, setData] = useState([]);
    const [syncData, setSyncData] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(10);
    const [filters, setFilters] = useState({
        userName: undefined
    });

    const [userSelected, setUserSelected] = useState({});

    const handleOnClickOption = (e, data) => {
        let modalCopy = {...modal}
        modalCopy.array[Constants.MODAL_COURSE_ENROLL_USER].open = true
        modalCopy.array[Constants.MODAL_COURSE_ENROLL_USER].header.subtitle = data.userName
        modalCopy.array[Constants.MODAL_COURSE_ENROLL_USER].header.title = data.givenName + " " + data.familyName
        setModal(modalCopy)
        
        setUserSelected(data)
    };

    const columns = [
        {
            cell: row => (
                <>
                { row.isAvailable ? <CheckCircleOutlineIcon className="text-success" />: 
                <BlockIcon className="text-danger" />}
                </>
            ),
            width: '56px', // custom width for icon button
            style: {
                borderBottom: '1px solid #FFFFFF',
                marginBottom: '-1px',
            },
        },        
        {
            name: 'Código Usuario',
            selector: row => row.userName,
        },
        {
            name: 'Nombres',
            selector: row => row.familyName,
        },
        {
            name: 'Apellidos',
            selector: row => row.givenName,
        },        
        {
            name: 'Email',
            selector: row => row.emailAddress
        },        
        {
            name: 'Teléfono',
            selector: row => row.mobilePhone,
        },           
        {
            cell: row => <>
                <Menu menuButton={<MenuButton><IconButton><MoreHorizIcon/></IconButton></MenuButton>} 
                    transition
                    onItemClick={(e) => handleOnClickOption(e, row)}
                >
                    <MenuItem value="enrollment">
                        <PeopleIcon fontSize='small' className='me-1'/>Matrículas
                    </MenuItem>
                </Menu>
            </>,
            allowOverflow: true,
            button: true,
            // width: '56px',
        },              
    ];

    const fetchData = useCallback(async (page, pageLength, filters) => {
        console.log("fetchData", page, pageLength, filters)
        const filtersCopy = { ...filters }
        if (!filtersCopy.userName) delete filtersCopy.userName

        let data = await api.fetchUsers(page, pageLength, filtersCopy)
        setData(data.results)
        setTotalRows(data.paging.total)
        setSyncData(false)
    }, [])  

    useEffect(() => {        
        console.log("Welcome to User")
        setSyncData(true)
    }, [])

    useEffect(() => {
        console.log("syncData", syncData, filters, page, totalRows, pageLength)
        if (syncData) fetchData(page, pageLength, filters)
    }, [syncData])

	const handlePageChange = (page) => {
		console.log("handlePageChange", page, pageLength)
        setPage(page)
        setSyncData(true)
	};

	const handlePerRowsChange = async (newPerPage, page) => {
        // console.log("handlePerRowsChange", newPerPage, page)        
        setPage(page)
        setPageLength(newPerPage)
        setSyncData(true)
	}

    const handleOnFilter = (name, value) => {
        let updatedValue = {};
        updatedValue[name] = value

        setFilters(filters => ({
            ...filters,
            ...updatedValue
        }));

        console.log('Function ran in Child component', name, value, updatedValue, filters)
    }

    const handleSearch = (e) => {
        console.log("handleSearch", filters)
        setPage(1);
        setSyncData(true);
    }
    
	const subHeaderComponentMemo = React.useMemo(() => {
		return (
			<div className="d-flex align-items-center w-100 dt-header">
                <SearcherBox 
                    placeholder={ "Busque por código" }
                    dataFilter={ "userName" }
                    onChange={ handleOnFilter }
                    value={ filters.userName }
                />
                <IconButton className="btn-search ms-auto" onClick={ (e) => handleSearch(e) }>
                    <SearchIcon />
                </IconButton>                
            </div>
		);
	}, [filters]);

    const customStyles = {
        header: {
            style: {
                fontSize: '22px',
                // color: theme.text.primary,
                backgroundColor: '#21A5E3',
                minHeight: '56px',
                paddingLeft: '16px',
                paddingRight: '8px',
            },
        },
        subHeader: {
            style: {
                // backgroundColor: '#E34321',
                minHeight: '52px',
                'justify-content': 'flex-start',
                'padding-left': 0,
                'padding-right': 0
            },
        },
    };    

    return (
        <>
    
        <Row>
            <header className="panel-header simple-header px-5">
                <div className="panel-titles-container">
                    <div className="black-header-contents">
                    <h1 className="panel-title">Usuarios</h1>
                    </div>
                </div>
            </header>
        </Row>
        <Row>
            <div className="panel-content base-courses px-5" >
                <section>                
                
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={syncData}
                    progressComponent={
                        <Loader type="spinner-default" bgColor={"#F3B02F"} title={"Cargando"} color={'#FFFFFF'} size={75} />
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customStyles}                    
                />
                </section>
                {/* 



                <div class="control-row px-4">
                    <div class="row">
                    
                    <div class="col-6">
                        <SquareToggle defaultChecked={ toggleSelected } onChange={(e) => setToggleSelected(e.target.value)} />
                    </div>

                        
                    </div>
                </div>
                */}
        
        </div>
        </Row>
        
        <ModalCustom 
            identifier={Constants.MODAL_COURSE_ENROLL_USER}
            size={97}
            content={ 
                <LayerEnrollmentPerUser 
                    userData={ userSelected.id ? userSelected : undefined } 
                    modalId = { Constants.MODAL_COURSE_ENROLL_USER }
                /> 
            }
        />
        </>
    )
}

export default User;