import React, { useId, useState, useEffect, useRef, useContext } from 'react'
import { userContext } from '../../app/provider.js';
//import { useUserContext } from '../../app/provider.js';
// import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import SvgIcon from "../../components/SvgIcon"

import {ReactComponent as IconSearch} from './images/svg-image-35.svg';
import * as Constants from '../../constants'

import './style.css'

const SearcherBox = ({ id, value = "", placeholder, dataFilter, onChange }) => {
    const { t } = useTranslation();
    const randomId = useId();
    const new_id = id ? id : randomId
    const [localValue, setLocalValue] = useState(value);
    const [showClose, setShowClose] = useState(false);
    const inputRef = useRef(null);

    /*
    useEffect(() => {
        console.log("localValue", localValue)
        let str_length = localValue ? localValue.trim().length : 0
        setShowClose(str_length > 0)
        
        if (localValue != null) handleOnChange(localValue)
    }, [localValue])
    */

    const clearSearch = () => {
        let filter_name = inputRef.current.attributes['data-filter'].value
        setShowClose(false)
        setLocalValue("")
        onChange(filter_name, "")
        inputRef.current.focus()        
    }

    const handleOnChange = (e) => {
        let value = e.target.value
        let filter_name = e.target.attributes['data-filter'].value
        let str_length = value ? value.trim().length : 0
        setShowClose(str_length > 0)
        setLocalValue(value)
        // console.log("handleOnChange", filter_name, value, str_length)
        
        onChange(filter_name, value)
    }
    // setLocalValue(e.target.value)
    
    return (
        <bb-search-box id={ "searcher-input-" + new_id } className="searcher-input">
            <div>
                <SvgIcon size={ Constants.ICON_SIZE_SMALL } icon={ Constants.ICON_SEARCH } />
                <input type="text" ref={inputRef} placeholder={ placeholder } value={ localValue } data-filter={ dataFilter } onChange={(e) => handleOnChange(e)} />
                {showClose ?
                    <button aria-label="Borrar búsqueda" onClick={() => clearSearch()}>
                        <SvgIcon size={ Constants.ICON_SIZE_SMALL } icon={ Constants.ICON_CLOSE } />
                    </button>
                    : null
                }
            </div>
        </bb-search-box>
    );
};

export default SearcherBox