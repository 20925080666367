import React, { useId, useState, useEffect, useContext } from 'react'
import { userContext } from '../../app/provider.js';
//import { useUserContext } from '../../app/provider.js';
// import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


import * as Constants from '../../constants'

import {ReactComponent as IconSearch} from './images/svg-image-35.svg';
import {ReactComponent as IconClose} from './images/close_20.svg';
import {ReactComponent as IconActivity} from './images/gradebook/activity.svg';
import {ReactComponent as IconQuestionary} from './images/gradebook/questionary.svg';
import {ReactComponent as IconWithoutCategory} from './images/gradebook/without_category.svg';

import './style.css'

const SvgIcon = ({ size, icon }) => {
    const { t } = useTranslation();
    let iconComponent;
    let className = "svg-icon " + size + "-icon";

    if (icon == Constants.ICON_SEARCH) iconComponent = <IconSearch className= { className } />
    else if (icon == Constants.ICON_CLOSE) iconComponent = <IconClose className= { className } />
    else if (icon == Constants.ICON_ACTIVITY) iconComponent = <IconActivity className= { className } />
    else if (icon == Constants.ICON_QUESTIONARY) iconComponent = <IconQuestionary className= { className } />
    else if (icon == Constants.ICON_WITHOUT_CATEGORY) iconComponent = <IconWithoutCategory className= { className } />

    useEffect(() => {
        // console.log("alerts", user.alerts)
        // console.log(selected, '- Has changed')
    }, [])
    
    return (
        <bb-svg-icon size={ size } icon={ icon }>
            { iconComponent }            
        </bb-svg-icon>
    );
};

export default SvgIcon