import React, { useState, useEffect, useContext } from 'react'
import { Outlet } from "react-router-dom";
import { Container} from "react-bootstrap";
import LoaderPage from '../../components/LoaderPage';
import PermissionDenied from '../../pages/PermissionDenied'
import Sidebar from "../Sidebar";
import Header from "../Header";
import api from '../../api'
import { userContext } from '../../app/provider.js'
import { EVENT_TYPE_LOGIN_APP } from '../../helpers/constants'
const { getUserInfo } = require('../../helpers/utils')

const MainLayout = () => {
    const [user, setUser] = useContext(userContext);

    /*
    useEffect(() => {

        if (!user.loading) {            
            let jsonData = {
                userPermision: user.userPermision
            }
            let json = getUserInfo(user, null, null, null, null, EVENT_TYPE_LOGIN_APP, jsonData)
            api.addEventInfo(json)
        }

    }, [user.loading])
    */

    useEffect(() => {
    }, [])    

    return (
        <main>
            {
            // user.loading ? <LoaderPage/>
            // : user.userPermision ?
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Header />
                    <Container fluid>
                        <Outlet />
                    </Container>
                </div>
            </div>
            // : <PermissionDenied/>
            }
        </main>
    );
};

export default MainLayout