import React, { useState, useEffect, useContext } from 'react'
//import { useUserContext } from '../../app/provider.js';
// import { NavLink } from 'react-router-dom'
import ProgressBar from "../../components/ProgressBar";
import iconCourse from '../../images/course_64.png';
import { userContext, courseContext } from '../../app/provider.js';
import { MdMenuBook } from "react-icons/md";
import api from '../../api'
import { EVENT_TYPE_COURSE_PROGRESS } from '../../helpers/constants'
import './style.css'
const { getUserInfo } = require('../../helpers/utils')

const CourseGlobalProgress = ({ percentage, className, item }) => {
    const [user, setUser] = useContext(userContext);
    const [course, setCourse] = useContext(courseContext)

    const selectCourse = () => {
        console.log("onclick course", item.course.id, item.course.name
        , item.info.dashBoard.porc_progress_decimal
        , item.info.dashBoard.total_completed
        , item.info.dashBoard.total_no_completed
        , item.info.dashBoard.total_suitable)
        
        setCourse({...course, show: true, selected: item })
        
        let jsonData = {
            courseId: item.course.id,
            courseName: item.course.name, 
            percentageProgress: item.info.dashBoard.porc_progress_decimal,
            totalCompleted: item.info.dashBoard.total_completed,
            totalNoCompleted: item.info.dashBoard.total_no_completed,
            totalSuitable: item.info.dashBoard.total_suitable
        }

        let json = getUserInfo(user, item.course.pkDB, item.course.id
            , null, null, EVENT_TYPE_COURSE_PROGRESS, jsonData)
        api.addEventInfo(json)
    }

    return (
        <div className={ 'row course-global-progress ' + className } >
            <div className='col-4'>
                <div className="d-flex align-items-center">
                    {/* <img src={ iconCourse } alt="course-icon" className="icon-course-img me-2" /> */}
                    <MdMenuBook size={23} className="me-2" />
                    <span className="text-start fw-bolder d-flex align-items-center" style={ {cursor: 'pointer'} } onClick={() => selectCourse()}>
                        { item.course.name }
                    </span>
                </div>
            </div>
            <div className='col-8 d-flex align-items-center'>
                <div className='w-100'>
                    <ProgressBar percentage={ percentage } />
                </div>
            </div>
        </div>
    );
};

export default CourseGlobalProgress