import React, { useEffect, useContext } from "react";
import Loader from "react-js-loader";

import './style.css'

const LoaderCustomize = ({ size }) => {

    useEffect(() => {
        console.log("LoaderPage", "Entrando")
        size = size ? size : 75
    }, [])

    return (
        <Loader type="spinner-default" bgColor={"#F3B02F"} color={'#FFFFFF'} size={ size } />
    );
};

export default LoaderCustomize