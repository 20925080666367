import React from "react";
import { NavLink } from 'react-router-dom'
import logo from '../../images/logo.svg';
import { FaHome } from "react-icons/fa";
import { MdNotificationAdd } from "react-icons/md";
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import './style.css'

const Sidebar = props => {

return (
        <div className="d-flex flex-column flex-shrink-0 bg-black" id="sidebar-wrapper">
            <div className="sidebar-heading">
                <img src={logo} className="max-h-50px logo-default" />
            </div>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center sidebar-items">
                <li className="nav-item">
                    <NavLink to="/" className="nav-link py-3">
                        <span className='icon'><MenuBookIcon fontSize='large' /></span>
                        <span className='name-text pt-1'>Cursos</span>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/user" className="nav-link py-3">
                        <span className='icon'><PersonIcon fontSize='large' /></span>
                        <span className='name-text pt-1'>Usuarios</span>
                    </NavLink>                  
                </li>
            </ul>
        </div>
    );
};

export default Sidebar