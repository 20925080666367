import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { modalContext } from '../../app/provider.js';
import api from '../../api/bb.js'
import * as Constants from '../../constants/index.js'
import Loader from "../../components/Loader";
import ModalCustom from "../ModalCustom/index.js";
import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DataTable from 'react-data-table-component';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import LayerAttempts from "../../components/LayerAttempts";
import Moment from 'react-moment';
import TimelineIcon from '@material-ui/icons/Timeline';
import Filter1Icon from '@material-ui/icons/Filter1';
import {ReactComponent as ImageEmptyGradebook} from '../../images/svg-empty-gradebook.svg';


import InputGrade from "../../components/InputGrade"

import SvgIcon from "../../components/SvgIcon"

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


import './style.css'

const LayerGradebook = ({ courseId, userId, modalId }) => {
    const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT

    const [modal, setModal] = useContext(modalContext);
    const [dataColumns, setDataColumns] = useState([]);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [dataGrades, setDataGrades] = useState([]);
    const [gradesCompleted, setGradesCompleted] = useState(false);

    const [columnSelected, setColumnSelected] = useState(undefined)

    const fetchColumns = useCallback(async () => {
        let response = await api.fetchColumns(1, 1000, courseId)
        let data = response.results
        data.forEach(o => o.progress = Constants.PROGRESS_STATUS_PENDING)

        setDataColumns(prevData => [...prevData, ...data])
        setIsLoadingPage(false)
    }, [])

    const fetchGrades = useCallback(async () => {
        let page = 1
        let pageLength = 100
        let band = true        

        while (band) {            
            let response = await api.fetchGradesPerUser(page, pageLength, courseId, userId)
            let data = response.results
            data.forEach(o => o.page = page)
            page = page + 1            
            
            if(!response.paging.nextPage) {
                band = false
                setGradesCompleted(true)
            }
            setDataGrades(prevData => [...prevData, ...data])
        }
    }, [])

    useEffect(() => {
        console.log("Welcome LayerGradebook")
        fetchColumns();
        fetchGrades();
    }, [])

    useEffect(() => {
        if (dataGrades.length > 0) {
            dataGrades.forEach((element, index) => {
                const foundIndex = dataColumns.findIndex(x => x.id == element.columnId);

                if (foundIndex == -1) return;
                else {
                    let object = { ...dataColumns[foundIndex] }
                    object.score = element.displayGrade ? element.displayGrade.score : "";
                    object.status = element.status ? element.status.toUpperCase() : "";
                    object.firstAttemptId = element.firstAttemptId ? element.firstAttemptId : null;
                    object.progress = Constants.PROGRESS_STATUS_COMPLETE
                    dataColumns[foundIndex] = object;
                }
            })
        }
    }, [dataColumns])

    useEffect(() => {        
        console.log("Cambio dataGrades", dataColumns.length, dataColumns)
        let maxValue = Math.max.apply(null, dataGrades.map(function (o) { return o.page }));
        const dataGradesFilter = dataGrades.filter(x => x.page == maxValue);
        
        dataGradesFilter.forEach((element, index) => {
            const foundIndex = dataColumns.findIndex(x => x.id == element.columnId);

            if (foundIndex == -1) return;
            else {
                let object = { ...dataColumns[foundIndex] }
                object.score = element.displayGrade ? element.displayGrade.score : "";
                object.status = element.status ? element.status.toUpperCase() : "";
                object.firstAttemptId = element.firstAttemptId ? element.firstAttemptId : null;
                object.progress = Constants.PROGRESS_STATUS_COMPLETE
                dataColumns[foundIndex] = object;
            }
        })

    }, [dataGrades])

    const handleOnClickOption = (e, data) => {
        setColumnSelected(data.id)

        let modalCopy = {...modal}
        modalCopy.array[Constants.MODAL_ATTEMPT_GRADEBOOK].open = true
        modalCopy.array[Constants.MODAL_ATTEMPT_GRADEBOOK].previous = modalId
        modalCopy.array[Constants.MODAL_ATTEMPT_GRADEBOOK].header.title = data.columnName
        
        setModal(modalCopy)
    };

    const columns = [
        {
            cell: row => (
                <SvgIcon size={ Constants.ICON_SIZE_LARGE } icon={ Constants.ICON_ACTIVITY } />
            ),
            width: '4rem',
            style: {
                borderBottom: '1px solid #FFFFFF',
                marginBottom: '-1px',
            },
        }, 
        {
            name: 'Nombre del elemento',
            selector: row => row.columnName,
        },
        {
            name: 'Categoría',
            selector: row => row.category_title ? row.category_title : "-",
        },
        {
            name: 'Fecha de vencimiento',
            selector: row => (
                <>
                { row.dueDate ? <Moment date={row.dueDate} format={DATETIME_FORMAT} /> : "" }
                </>
            )
        },
        {
            name: 'Estado',
            selector: row => (
                <>
                {
                    row.progress == Constants.PROGRESS_STATUS_PENDING & !gradesCompleted ? <span class="progress-indicator loading"/> :
                    row.progress == Constants.PROGRESS_STATUS_PENDING & gradesCompleted ? "No hay entregas":
                    row.status == Constants.GRABEBOOK_STATUS_GRADED ? "Completado" : 
                    row.status == Constants.GRABEBOOK_STATUS_NEED_GRADED ? "Necesita calificación" : 
                    row.status
                }
                </>
            )
        },        
        {
            name: 'Calificación',
            selector: row => (
                <>
                {
                    row.progress == Constants.PROGRESS_STATUS_PENDING & !gradesCompleted ? <span class="progress-indicator loading"/> :
                    row.progress == Constants.PROGRESS_STATUS_PENDING & gradesCompleted ? <InputGrade grade={ "--" } possible={ row.possible }/> :
                    row.status == Constants.GRABEBOOK_STATUS_GRADED ? <InputGrade grade={ row.score } possible={ row.possible }/> :
                    row.status == Constants.GRABEBOOK_STATUS_NEED_GRADED ? <InputGrade grade={ "--" } possible={ row.possible }/> :
                    "Desconocido"
                }
                </>
            )
        },
        {
            cell: row => <>
                <Menu menuButton={<MenuButton><IconButton><MoreHorizIcon/></IconButton></MenuButton>} 
                    transition
                    onItemClick={(e) => handleOnClickOption(e, row)}
                >
                    <MenuItem value="attempt" 
                        disabled={ 
                            (row.progress == Constants.PROGRESS_STATUS_PENDING & gradesCompleted) || 
                            (row.progress == Constants.PROGRESS_STATUS_COMPLETE & row.firstAttemptId == null ) 
                            ? true : false 
                        }
                    >
                        <Filter1Icon fontSize='small' className='me-1'/>
                        Intentos
                    </MenuItem>
                    {/*
                    <MenuItem value="log">
                        <TimelineIcon fontSize='small' className='me-1'/>
                        Historial
                    </MenuItem>      
                    */}
                </Menu>           
            </>,
            allowOverflow: true,
            button: true
        }
    ];

    return (
        <>
        {   isLoadingPage ? <Loader/> :
            <>            
            {   dataColumns.length ?
                <Row>
                    <ModalCustom 
                        identifier={Constants.MODAL_ATTEMPT_GRADEBOOK}
                        previus={Constants.MODAL_ENROLL_GRADEBOOK}
                        size={93}
                        content={ 
                            <LayerAttempts courseId= { courseId } userId={ userId } columnId={ columnSelected ? columnSelected : undefined } />
                        }
                    />          
                    <div class="tabular-list-container roster-list-view">
                        <DataTable columns={columns} data={dataColumns} noDataComponent="" />
                    </div>
                </Row> : 
                <div class="is-empty-wrapper">
                    <ImageEmptyGradebook />
                    <h1 class="is-empty-heading"><strong>Aún no asignó ningún trabajo en el curso.</strong></h1>
                </div>
            }
            </>                        
        }
        </>

    );
};

export default LayerGradebook