import axios from 'axios';
import { STATUS_OK, STATUS_NOK } from '../helpers/constants'

const { bbGenerateError } = require('../helpers/utils')
const Helpers = require('../helpers')
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

const getInfoTracking = (userId) =>
    axios.all([
        axios.get(`${PUBLIC_URL}/api/tracking/users/${userId}`),
        axios.get(`${PUBLIC_URL}/api/tracking/users/alerts/${userId}`)
    ]).then(axios.spread((tracking, alerts) => {
        return {
            status: STATUS_OK, 
            data: {
                tracking: tracking.data,
                alerts: alerts.data
            }
        }
    })).catch(error => {
        const jsonError = bbGenerateError(error)
        return {
            status: STATUS_NOK, 
            data: jsonError
        }
    })

const getUserInfo = (userId) =>
    axios.get(`${PUBLIC_URL}/bb/users/${userId}`)
    .then(response => {
        return response
    }).catch(error => {
        return error.response
    })

const getCoursesTracking = (userId) =>
    axios.get(`${PUBLIC_URL}/api/tracking/users/${userId}`)
    .then(response => {
        return response
    }).catch(error => {
        return error.response
    })    

const getAlertsByUser = (userId) =>
    axios.get(`${PUBLIC_URL}/api/tracking/users/alerts/${userId}`)
    .then(response => {
        return response
    }).catch(error => {
        return error.response
    })

const addLoginInfo = (payload) =>
    axios.post(`${PUBLIC_URL}/api/tracking/users/login-info-add`, payload)
    .then(response => {
        return response
    }).catch(error => {
        return error.response
    })

const addEventInfo = (payload) =>
    axios.post(`${PUBLIC_URL}/api/tracking/users/event-add`, payload)
    .then(response => {
        return response
    }).catch(error => {
        return error.response
    })

const api = {
    getCoursesTracking,
    addLoginInfo,
    getAlertsByUser,
    getInfoTracking,
    getUserInfo,
    addEventInfo
}

export default api