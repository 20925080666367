import React, { createContext, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import * as Constants from '../constants'

const Provider = ({ children }) => {

    const [user, setUser] = useState({
        userPk: undefined,
        userPkDB: undefined,
        userId: undefined,
        fullname: undefined,
        roles: undefined,
        userPermision: false,
        publicIP: undefined,
        enterAs: false,
        userPkEnterAs: undefined,
        userIdEnterAs: undefined,
        fullnameEnterAs: undefined,
        alerts: {
            size: 0,
            records: []
        },
        loading: true,
        browser_name: undefined,
        browser_version: undefined,
        os_name: undefined,
        os_version: undefined,
        device_type: undefined,
        device_brand: undefined
    })
    const [course, setCourse] = useState({show: false, selected: null, tracking: []})

    const [modal, setModal] = useState({
        modal_open: 0,
        modal_active: undefined,
        modal_previous: undefined,
        array: {
            [Constants.MODAL_COURSE_ENROLL]: { open: false, previous: undefined, header: {subtitle: undefined, title: undefined} },
            [Constants.MODAL_COURSE_ENROLL_USER]: { open: false, previous: undefined, header: {subtitle: undefined, title: undefined} },            
            [Constants.MODAL_ENROLL_GRADEBOOK]: { open: false, previous: undefined, header: {subtitle: undefined, title: "LIBRO DE CALIFICACIONES"} },
            [Constants.MODAL_ATTEMPT_GRADEBOOK]: { open: false, previous: undefined, header: {subtitle: undefined, title: undefined} }
        }
    })

    return (
        <CookiesProvider>
            <userContext.Provider value={[user, setUser]}>
                <courseContext.Provider value={[course, setCourse]}>
                    <modalContext.Provider value={[modal, setModal]}>
                        { children }
                    </modalContext.Provider>
                </courseContext.Provider>
            </userContext.Provider>
        </CookiesProvider>
    );
}

export default Provider;
export const userContext = React.createContext();
export const courseContext = React.createContext();
export const modalContext = React.createContext();
