const axios = require('axios')
var constants = require('../constants')

class Helpers {
    static getDomainUrl = (event) => {
        try {
            const headers = event.headers
            const protocol = headers['X-Forwarded-Proto']
            const url = `${protocol}://${event.requestContext.domainName}/${event.requestContext.stage}`
    
            return url
        } catch (err) {
            throw new Error(`Domain URL error (${err.name}) => ${err.message}`)
        }
    }

    static renameKeys = (arr, nameMap) => {
        // loop around our array of objects
        for(var i = 0; i < arr.length; i++) {
            var obj = arr[i];
            // loop around our name mappings
            for(var j = 0; j < nameMap.length; j++) {
                var thisMap = nameMap[j];
                if(obj.hasOwnProperty(thisMap.from)) {
                    // found matching name
                    obj[thisMap.to] = obj[thisMap.from];
                    delete obj[thisMap.from];
                }
            }
        }
    } 

    static buildUrl = (baseUrl, queryParams) => {
        let url = baseUrl
        if (Object.keys(queryParams).length === 0) return url

        const paramsString = Object.keys(queryParams)
        .map(i => `${i}=${ queryParams[i] ? queryParams[i] : '' }`)
        .join('&')        

        if (baseUrl.includes('?')) url = `${baseUrl}&${paramsString}`
        else url = `${baseUrl}?${paramsString}`
    
        return url;
    }

    static callApiWithToken = async (url, token) => {
        try {
            let accessToken = 'Bearer ' + token.access_token

            let response = await axios.get(url, {
                responseType: 'json',
                headers: { 
                    'Authorization': accessToken 
                }
            })

            return response
        } catch (err) {
            throw Error(`${ err.message } from ${ url }`)
        }
    }

    static strToInteger = (str) => {
        try {
            const number = Number(str);
            const isInteger = Number.isInteger(number);

            if (isInteger) return number
            else throw new Error(`${str} no es número entero.`)
        } catch (err) {
            throw new Error(err)
        }
    }
    
    static lambdaSuccess = (data = null) => {
        return {
            statusCode: 200,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }
    }

    static lambdaError = (err) => {
        let response = {
            status: constants.STATUS_NOK
        }
    
        let status = err.status ? err.status : 500
        response.message = err.message
        
        return {
          statusCode: status,
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(response),
          isBase64Encoded: false
        } 
    }

    static formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }   
}

module.exports = Helpers