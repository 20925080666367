import React, { useState, useEffect, useContext } from 'react'
import qIufhof from '../../images/qIufhof.png';

import './style.css'

const PermissionDenied = () => {

    useEffect(() => {
    }, [])

    return (
        <div className="container vh-100 permission-denied">
            <div className='row h-100 justify-content-center'>
                <div className='col-sm-5 justify-content-center'>
                    <img src={ qIufhof } className='px-5' />
                    <h2 className='text-center'>Acceso denegado, no cuentas con los permisos necesarios.</h2>
                </div>
            </div>
        </div>
    )
}

export default PermissionDenied;