import React, { useEffect, useContext } from "react";
import Loader from "react-js-loader";

import './style.css'

const InputGrade = ({grade, possible}) => {

    useEffect(() => {
        console.log("InputGrade", "Entrando")
    }, [])

    return (
        <div class="wrapping-input-style">
            <span>{grade}</span>
            <span class="points-text">/<bdi>{possible}</bdi></span>
        </div>
    );
};

export default InputGrade