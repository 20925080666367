"use strict";

class CustomError extends Error {
    constructor(message, status) {
        super(message);
        this.status = status ? status : 500;
        this.name = 'CustomError';
    }
}

// module.exports = { CustomError }

export default { CustomError }