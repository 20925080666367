import React, { useState, useEffect } from "react";
//import { useUserContext } from '../../app/provider.js';
// import { NavLink } from 'react-router-dom'

import './style.css'

const ProgressBar = ({ percentage }) => {
    //const [user, setUser] = useUserContext();

    const [percSuccess, setPercSuccess] = useState(0);
    const [percDanger, setPercDanger] = useState(100);

    useEffect(() => {
        let local_percentage_success = percentage
        setPercSuccess(local_percentage_success)
        setPercDanger( 100 - local_percentage_success )
        // let local_percentage_success = (percentage * 100)
        // setPercSuccess( local_percentage_success.toFixed(2) )
        // setPercDanger( ( 100 - local_percentage_success ).toFixed(2) )
    }, [])
    
    return (
        <div className="progress">
            <div className="progress-bar bg-color-success" role="progressbar" style={{ width: percSuccess + '%' }}><span className="fw-bolder">{ percSuccess }%</span></div>
            <div className="progress-bar bg-color-danger" role="progressbar" style={{ width: percDanger + '%' }}><span className="fw-bolder">{ percDanger }%</span></div>
        </div>
    );
};

export default ProgressBar