import React, { useState, useEffect, useContext, useCallback } from 'react'
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { userContext } from './app/provider.js'
import { useCookies } from 'react-cookie';
import helpers from './app/helpers'
import api from './api'
import DeviceDetector from "device-detector-js"

import Sidebar from "./components/Sidebar";
import Header from "./components/Header"
import { Container} from "react-bootstrap";
import Home from './pages/Home'
import Notification from './pages/Notification'
import PermissionDenied from './pages/PermissionDenied'
import MainLayout from './components/MainLayout'
import './App.css';
import './dashboard.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import Course from './pages/Course/index.js';
import User from './pages/User/index.js';
const { stringToPk } = require('./helpers/utils')

const App = () => {
  const [user, setUser] = useContext(userContext);
  const [cookies, setCookie] = useCookies(['data_user']);
  const userRol = process.env.REACT_APP_USER_ROL
  // const userRolAdmin = process.env.REACT_APP_USER_ROL_ADMIN
  const userAgent = window.navigator.userAgent

  const loadInfoUser = useCallback(async (info, roles) => {   
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(userAgent)
    
    let publicIP = await helpers.getClientIp()
    let userInfo = await api.getUserInfo(info.bb_user_id)

    let fullname = (userInfo.data.name.given.length > 0 ? userInfo.data.name.given : "") + " " + (userInfo.data.name.family.length > 0 ? userInfo.data.name.family : "")
    fullname = fullname.trim()

    setUser({
      ...user,
      userPk: userInfo.data.id,
      userPkDB: stringToPk(userInfo.data.id),
      userId: userInfo.data.userName,
      fullname: fullname,
      roles: roles.arr,
      userPermision: roles.userPermision,
      loading: false,
      enterAs: false,
      userPkEnterAs: info.bb_user_pk,
      userIdEnterAs: info.bb_user_id,
      fullnameEnterAs: info.bb_user_full_name,
      publicIP: publicIP,
      browser_name: device.client.name,
      browser_version: device.client.version,
      os_name: device.os.name,
      os_version: device.os.version,
      device_type: device.device.type,
      device_brand: device.device.brand,
    })

    const json = {
        userId: cookies.data_user.bb_user_id,
        browser_name: device.client.name,
        browser_version: device.client.version,
        os_name: device.os.name,
        os_version: device.os.version,
        device_type: device.device.type,
        device_brand: device.device.brand,
        ip_address: publicIP
    }

  }, [])  

  useEffect(() => {    
    if (cookies.data_user) {
        const roles = helpers.stringToRoles(cookies.data_user.secondary_institution_role, userRol)

        // console.log("App cookies", cookies.data_user, roles)
        // loadInfoUser(cookies.data_user, roles)        
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/denied" element={ <PermissionDenied /> } />
        </Route>
        <Route element={ <MainLayout /> } >
          <Route path="/" element={<Course />} />
          <Route path="/user" element={<User />} />
        </Route>
      </Routes>
    </ BrowserRouter>
  )
}

export default App;