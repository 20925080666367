export const TOGGLE_GRID = "TOGGLE_GRID"
export const TOGGLE_TABLE = "TOGGLE_TABLE"


export const ICON_SIZE_SMALL = "small"
export const ICON_SIZE_MEDIUM = "medium"
export const ICON_SIZE_LARGE = "large"
export const ICON_SEARCH = "search"
export const ICON_CLOSE = "close"
export const ICON_ACTIVITY = "activity"
export const ICON_QUESTIONARY = "questionary"
export const ICON_WITHOUT_CATEGORY = "without_category"
// export const ICON_QUESTIONARY = "questionary"

// import {ReactComponent as IconWithoutCategory} from './images/gradebook/without_category


export const MENU_FROM_MENU = "fromMenu"
export const MENU_FROM_MENU_ITEM = "fromMenuItem"
export const MENU_GO_TO_COURSE = "gotocourse"
export const MENU_ENROLLMENT = "enrollment"


export const MODAL_COURSE_ENROLL = "modal_course_enroll"
export const MODAL_COURSE_ENROLL_USER = "modal_course_enroll_user"
export const MODAL_ENROLL_GRADEBOOK = "modal_enroll_gradebook"
export const MODAL_ATTEMPT_GRADEBOOK = "modal_attempt_gradebook"

export const API_MEMBERSHIP_AVAILABLE_IND = "Enabled"
export const API_MEMBERSHIP_DISABLED_IND = "Disabled"

export const PROGRESS_STATUS_PENDING = "PENDING"
export const PROGRESS_STATUS_COMPLETE = "COMPLETED"

export const GRABEBOOK_STATUS_GRADED = "GRADED"
export const GRABEBOOK_STATUS_NEED_GRADED = "NEEDSGRADING"

export const API_AVAILABLE_IND = "Yes"
export const API_DISABLED_IND = "No"
