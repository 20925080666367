import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Chart } from "react-chartjs-2";
import { Doughnut, Pie } from "react-chartjs-2";
import "chartjs-plugin-doughnutlabel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CourseGlobalProgress from "../../components/CourseGlobalProgress/index.js"
import SquareToggle from "../../components/SquareToggle/index.js"
import { Card } from 'react-bootstrap'
import GaugeChart from 'react-gauge-chart'
import { AiOutlineInfoCircle } from "react-icons/ai";


import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import ModalCourse from "../../components/ModalCustom/index.js"
import ContentBoxText from "../../components/ContentBoxText/index.js"
import { BsHandIndex } from "react-icons/bs";
import Loader from "react-js-loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ReactComponent as IconSearch} from '../../images/svg-image-35.svg';

import './style.scss'
import { userContext, courseContext } from '../../app/provider.js';
import api from '../../api/index.js'
import * as Constants from '../../constants'
import { STATUS_OK, EVENT_TYPE_LOAD_COURSES } from '../../helpers/constants.js'
const { getUserInfo } = require('../../helpers/utils.js')


const Home = () => {
    let showCourseModal = false;

    const [user, setUser] = useContext(userContext);
    const [course, setCourse] = useContext(courseContext);
    const [loadTracking, setLoadTracking] = useState(false);
    const [errorTrackingInd, setErrorTrackingInd] = useState(false);
    const [errorTracking, setErrorTracking] = useState('');
    const [toggleSelected, setToggleSelected] = useState(Constants.TOGGLE_TABLE);

    const getInfoTracking = useCallback(async (userId) => {

        const { status, data } = await api.getInfoTracking(userId)
        // const { tracking, alerts } = await api.getInfoTracking(userId)
        // console.log("status", status, data)
        let jsonData = null
        if (status == STATUS_OK) {
            setUser({...user, alerts: data.alerts})
            setCourse({...course, tracking: data.tracking})

            jsonData = {
                totalCourse: data.tracking.detail.length
            }

        } else {
            setErrorTrackingInd(true)
            setErrorTracking(data.message)
        }
        
        setLoadTracking(true)

        let json = getUserInfo(user, null, null, null, null, EVENT_TYPE_LOAD_COURSES, jsonData)
        api.addEventInfo(json)
    }, [])

    const handleChange = (e) => {
        console.log("holaa", e.target)
    }    

    useEffect(() => {
        const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

        if (!user.loading) {
            console.log("Home", "Entrando", user.userId)
            getInfoTracking(user.userId)
        }

    }, [user.loading])

    useEffect(() => {
        console.log("toggleSelected", toggleSelected)
    }, [toggleSelected])    

    return (
        <>
    <Container fluid>
        <Row>
            <header className="panel-header simple-header px-4">
                <div class="panel-titles-container">
                    <span class="panel-sub-title">CIAP.1306.223413.15034.V • TERMODINÁMICA - 15034 (Virtual)</span>
                    <div class="black-header-contents">
                    <h1 class="panel-title" bb-translate="" tabindex="-1">Lista</h1>
                    </div>
                </div>
                <div class="header-actions-menu">
                    <div class="header-actions-menu__item active no-select">
                        <button class="anchor-button" id="search-button" bb-click-focus="#search-roster-field" ng-click="courseRoster.showSearchBox()" bb-translate-attrs="{ title: 'course.roster.labels.search', 'aria-label':'course.roster.labels.search' }" title="Buscar en la lista">
                            <IconSearch className="svg-icon default medium-icon" />
                            { /* <img src={IconSearch} className="header-search-icon" alt="React Logo" /> */ }
                        </button>
                    </div>
                </div>
            </header>
        </Row>
        <Row>
            <div className="panel-content">
                <div class="control-row px-4">
                    {/* collapse */}
                    <div class="row">
                    
                    <div class="col-6">
                        <SquareToggle defaultChecked={ toggleSelected } onChange={(e) => setToggleSelected(e.target.value)} />
                    </div>

                        
                    </div>
                </div>
    
        <ul class="roster list tabular-list">
        
        {/* js-roster-members-list-item highlight highlight-hover special special-hover */}

        </ul>
        </div>
        </Row>
        
        </Container>  
        </>
    )
}

export default Home;