import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import { modalContext } from '../../app/provider.js';
import api from '../../api/bb.js'
import * as Constants from '../../constants'
import Loader from "../../components/Loader";
import NotificationContainer from "../../components/NotificationContainer";

import DataTable from 'react-data-table-component';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import Moment from 'react-moment';
import parse from 'html-react-parser';

import './style.css'
const Helpers = require('../../helpers')

const LayerAttempts = ({ courseId, userId, columnId, modalId }) => {
    const ROWS_PER_PAGE = process.env.REACT_APP_ROWS_PER_PAGE
    const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT

    const [modal, setModal] = useContext(modalContext);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoadingHasMore, setIsLoadingHasMore] = useState(false);
    const [data, setData] = useState([]);
    const observerTarget = useRef(null);
    const [hasMore, setHasMore] = useState(true)
    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(ROWS_PER_PAGE)    

    const fetchData = useCallback(async (page) => {
        let response = await api.fetchAttemptsPerUserColumn(page, pageLength, courseId, userId, columnId)
        if(page == 1) setIsLoadingPage(false)
        setIsLoadingHasMore(false)
        setData(prevData => [...prevData, ...response.results])
        setPage(prevPage => prevPage + 1)
        if (!response.paging) setHasMore(false)
    }, [])
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    let page = observerTarget.current.attributes['data-page'].value
                    console.log("isIntersecting", page)
                    if (page > 1) {
                        setIsLoadingHasMore(true)
                    }

                    fetchData(page)
                }
            },
            { threshold: 1 }
        );
      
        if (observerTarget.current) {
          observer.observe(observerTarget.current);
        }
      
        return () => {
          if (observerTarget.current) {
            observer.unobserve(observerTarget.current);
          }
        };
    }, [observerTarget]);

    const columns = [        
        {
            name: 'Fec. intento',
            selector: row => (
                <>
                { row.attemptDate ? <Moment date={row.attemptDate} format={DATETIME_FORMAT} /> : "-" }
                </>
            )
        },
        {
            name: 'Estado',
            selector: row => (
                <>
                {
                    row.status.toUpperCase() == Constants.GRABEBOOK_STATUS_GRADED ? "Completado" : 
                    row.status.toUpperCase() == Constants.GRABEBOOK_STATUS_NEED_GRADED ? "Necesita calificación" : 
                    row.status.toUpperCase() 
                }
                </>
            )
        },        
        {
            name: 'Calificación',
            selector: row => (
                <>
                { row.score ? row.score : "-" }
                </>
            )
        },
        {
            name: 'Fec. envío',
            selector: row => (
                <>
                { row.attemptReceipt ? <Moment date={row.attemptReceipt.submissionDate} format={DATETIME_FORMAT} /> : "" }
                </>
            )
        },        
        {
            name: '# comprobante entrega',
            // width: "auto",
            selector: row => (
                <>
                { row.attemptReceipt ? row.attemptReceipt.receiptId : "" }
                </>
            )
        },        
        {
            name: 'Tamaño',
            selector: row => (
                <>
                { Helpers.formatBytes(row.attemptReceipt.submissionTotalSize) }
                </>
            )
        },        
        {
            id: "feedback",
            name: 'Comentarios generales',
            selector: row => (
                <>
                { row.feedback ? parse(row.feedback.replace(/<br>$/, '')) : "" }
                </>
            )
        },              
    ];

    return (        
        <>
        { isLoadingPage && <Loader/> }
        <div>
            <div class="tabular-list-container roster-list-view">
                <DataTable columns={columns} data={data} noDataComponent="" />
            </div>
        
            { hasMore &&
                <div ref={observerTarget} data-page={page}></div>
            }

            { isLoadingHasMore && <NotificationContainer/> }
        </div>
        </>
    );
};

export default LayerAttempts